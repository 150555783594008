
export const Queryparams = {
    url: "https://ml2centralapi.bistaya.com/api/v1",
    powerkey: "poTp0tv0Lt3s5v4h"
}

export const clrs = {
    bglogin: "#3B0962",
    bgloginmiddle: "#130320",
    lable: "#955BCC",
    white: "white",
    brdr: "#56478C",
    txtinptbrdr: "#955BCC",
    btnhvr: "#CCB4C2"
}
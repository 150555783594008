import { Box, keyframes, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;


const LoadingComponent = () => {
    const spinAnimation = `${spin} infinite 4s linear`;
    const [showHide, setShowHide] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setShowHide(!showHide)
        }, 1000);
        return () => clearInterval(interval);
    }, [showHide]);

    return (
        <Box
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            flexDir={'column'}
        >
            <Box animation={spinAnimation}>
                <Image
                    src='/central.png'
                    boxSize={'100px'}
                />
            </Box>
            <Text
                color={'white'}
                mt={5}
                opacity={showHide ? 0 : 1}
                transition={"opacity 1s ease"}
                fontSize={12}
            >
                Fetching your credentials...
            </Text>
        </Box>

    )
}

export default LoadingComponent
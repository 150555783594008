import React, { useState } from 'react'
import { Box, Image, Input, Text, Stack, Button, useToast, Spinner } from '@chakra-ui/react'
import { Queryparams, clrs } from '../Constants/Constants'
import { useNavigate } from 'react-router-dom'
import { UserLogin } from '../Queries/Queries'
import { spin } from '../Loading/LoadingComponent'
var CryptoJS = require("crypto-js");


const Login = ({ setIsSignedIn }) => {
    const [uname, setUname] = useState('')
    const [pword, setPword] = useState('')
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate()
    const spinAnimation = `${spin} infinite 4s linear`;

    const onLogin = () => {
        setLoading(true)

        UserLogin(uname, pword).then(res => {
            if (res.data) {
                setTimeout(() => {
                    localStorage.setItem('reduxPersistAuth', CryptoJS.AES.encrypt(JSON.stringify(res.data), Queryparams.powerkey).toString());
                    setLoading(false)
                    setIsSignedIn(true)
                    navigate("/")
                }, 2000);
            } else {
                toast({
                    title: 'LOGIN ERROR!',
                    description: res.response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
                setLoading(false)
            }
        }).catch(() => {
            toast({
                title: 'LOGIN ERROR!',
                description: "Ooops! Something went wrong.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
            setLoading(false)
        })
    }
    return (
        <Box bg={clrs.bglogin} h={"100vh"} justifyContent={'center'} alignItems={'center'} display={'flex'} >

            <Box
                borderWidth={1}
                borderColor={clrs.brdr}
                borderRadius={5}
                display={'flex'}
                flexDir={'row'}
                padding={10}
                backgroundColor={clrs.bgloginmiddle}
            >
                {
                    loading ?
                        <Box justifyContent={'center'} alignItems={'center'} animation={spinAnimation} display={'flex'}>
                            <Image
                                src='/central.png'
                                boxSize={'200px'}
                            />
                        </Box>
                        :
                        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Image
                                src='/central.png'
                                boxSize={'200px'}
                            />
                        </Box>

                }
                <Box
                    borderLeftWidth={2}
                    borderColor={clrs.brdr}
                    paddingLeft={10}
                    marginLeft={10}
                >
                    <Stack spacing={3}>
                        <Text
                            color={clrs.lable}
                        >
                            Username
                        </Text>
                        <Input
                            autoFocus
                            color={clrs.white}
                            size='md'
                            value={uname}
                            onChange={e => setUname(e.target.value)}
                            borderColor={clrs.txtinptbrdr}
                        />
                        <Text
                            color={clrs.lable}
                        >
                            Password
                        </Text>
                        <Input
                            color={clrs.white}
                            size='md'
                            value={pword}
                            onChange={e => setPword(e.target.value)}
                            borderColor={clrs.txtinptbrdr}
                        />
                        {
                            loading ?
                                <Button
                                    backgroundColor={clrs.txtinptbrdr}
                                    color={clrs.white}
                                    size='md' _hover={{
                                        background: clrs.btnhvr,
                                        color: "black",
                                    }}
                                >

                                    <Spinner size='sm' marginRight={5} color={clrs.white} />
                                    Logging in...
                                </Button>
                                :
                                <Button
                                    backgroundColor={clrs.txtinptbrdr}
                                    color={clrs.white}
                                    size='md'
                                    _hover={{
                                        background: clrs.btnhvr,
                                        color: "black",
                                    }}
                                    onClick={() => onLogin()}
                                >
                                    Login
                                </Button>
                        }

                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default Login
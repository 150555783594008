import { Box, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { clrs } from '../Constants/Constants'
import { FaPencilAlt } from 'react-icons/fa'


const TableConfigs = ({ configs, onClickEditConfigs }) => {
    return (
        <Box borderWidth={'1px'} borderColor={clrs.lable} borderRadius={5} padding={5}>

            <Tooltip
                label={"Update Draw Configurations"}
                placement={'right'}
            >
                <HStack justifyContent={'center'}>
                    <Text fontWeight={'bold'} color={'whiteAlpha.700'} marginBottom={'10px'}>{(configs.gcode ? configs.gcode.toUpperCase() : "") + " Draw Configurations"}</Text>
                    <IconButton
                        onClick={() => onClickEditConfigs()}
                        marginLeft={"5px"}
                        boxSize={"30px"}
                        _hover={{
                            backgroundColor: 'whiteAlpha.400'
                        }}
                        size={'xs'}
                        bg={'transparent'}
                        icon={
                            <FaPencilAlt
                                color='orange'
                                size={"15px"}
                            />
                        }
                        color={'white'}
                    />
                </HStack>
            </Tooltip>

            <Box>
                <Text color='white' fontWeight={'bold'} paddingY={'10px'}>Black Out</Text>
                <Box paddingY={'5px'} paddingLeft={'5px'} display={'flex'} flexDir={'row'} borderWidth={'1px'} borderColor={clrs.lable}>
                    <Text color='white' w={'50%'}>Start</Text>
                    <Text color='white' >{configs.blk_start}</Text>
                </Box>
                <Box paddingY={'5px'} paddingLeft={'5px'} display={'flex'} flexDir={'row'} borderWidth={'1px'} borderColor={clrs.lable}>
                    <Text color='white' w={'50%'}>End</Text>
                    <Text color='white'>{configs.blk_end}</Text>
                </Box>
            </Box>
            <Box>
                <Text color='white' fontWeight={'bold'} paddingY={'10px'}>Draw Period</Text>
                <Box paddingY={'5px'} paddingLeft={'5px'} display={'flex'} flexDir={'row'} borderWidth={'1px'} borderColor={clrs.lable}>
                    <Text color='white' w={'50%'}>Start</Text>
                    <Text color='white'>{configs.drw_start}</Text>
                </Box>
                <Box paddingY={'5px'} paddingLeft={'5px'} display={'flex'} flexDir={'row'} borderWidth={'1px'} borderColor={clrs.lable}>
                    <Text color='white' w={'50%'}>End</Text>
                    <Text color='white'>{configs.drw_end}</Text>
                </Box>
            </Box>

            <Box>
                <Text color='white' fontWeight={'bold'} paddingY={'10px'}>Entry Duplicate Interval</Text>
                <Box paddingY={'5px'} paddingLeft={'5px'} display={'flex'} flexDir={'row'} borderWidth={'1px'} borderColor={clrs.lable}>
                    <Text color='white' w={'80%'}>Seconds Not Duplicate</Text>
                    <Text color='white'>{configs.sec_dup}</Text>
                </Box>
            </Box>

        </Box>
    )
}
export default TableConfigs
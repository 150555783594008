import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { clrs } from '../Constants/Constants'

const Sidebar = ({ games, onSelGame, selGame }) => {
    return (
        <Box
            // bg={'blackAlpha.400'}
            h={'86vh'}
            w={100}
            overflowY={'auto'}
            marginLeft={2}
            marginTop={2}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={games.length < 21 ? 'center' : 'flex-start'}
            sx={{
                '&::-webkit-scrollbar': {
                    width: '5px',
                    borderRadius: '8px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.bgloginmiddle,
                },
            }}
        >
            {
                games.map((gm) => (
                    <Box
                        key={gm.gcode}
                        onClick={() => gm.gcode.length > 0 ? onSelGame(gm) : {}}
                    >
                        {
                            selGame.gcode === gm.gcode ?
                                <Text
                                    marginY={2}
                                    fontSize={15}
                                    fontWeight={'700'}
                                    color={'white'}
                                    w={'95px'}
                                    borderRadius={5}
                                    paddingY={15}
                                    bg={'whiteAlpha.500'}
                                    textAlign={'center'}
                                >
                                    {gm.gcode.length > 0 ? gm.gcode.toUpperCase() : "---"}
                                </Text>
                                :
                                <Text
                                    borderRadius={5}
                                    marginY={"1px"}
                                    paddingY={2}
                                    w={'95px'}
                                    fontSize={15}
                                    fontWeight={'700'}
                                    color={clrs.lable}
                                    bg={'blackAlpha.400'}
                                    _hover={{
                                        width: "75px",
                                        // height: "35px",
                                        cursor: 'pointer',
                                        fontSize: 18,
                                        paddingY: '5px',
                                        marginY: "10px",
                                        bg: clrs.white,
                                        transition: '0.3s',
                                        zIndex: 5,
                                    }}
                                    textAlign={'center'}
                                >
                                    {gm.gcode.length > 0 ? gm.gcode.toUpperCase() : "---"}
                                </Text>
                        }
                    </Box>
                ))
            }
        </Box >
    )
}

export default Sidebar
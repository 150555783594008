import axios from "axios";
import { Queryparams } from "../Constants/Constants";
import moment from "moment";
import momentTz from 'moment-timezone'

export const FetchGames = async (token) => {
    return axios({
        url: Queryparams.url + "/games",
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}


export const UserLogin = async (uname, pword) => {
    return axios({
        url: Queryparams.url + "/user/login",
        method: "POST",
        data: {
            username: uname,
            password: pword
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const FetchControls = async (gcode, url, token) => {
    return axios({
        url: url + "/control/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const FetchResults = async (drawdate, token) => {
    return axios({
        url: Queryparams.url + "/results?drawdate=" + drawdate,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const UpdateControl = async (token, seldata, newamnt) => {
    return axios({
        url: Queryparams.url + "/control/set",
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: {
            gcode: seldata.gcode,
            combi: seldata.combi,
            ctrl_amt: parseFloat(newamnt)
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const FetchDrawConfigs = async (url, gcode, token) => {
    return axios({
        url: url + "/draw/configs/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}

export const UpdateConfigs = async (token, url, data) => {
    return axios({
        url: url + "/draw/configs",
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: {
            gcode: data.gcode,
            drw_start: data.drw_start,
            drw_end: data.drw_end,
            blk_start: data.blk_start,
            blk_end: data.blk_end,
            sec_dup: data.sec_dup
        },
        timeout: 10000,
    }).then(res => res)
        .catch(errmsg => errmsg);
}


export const FetchRunning = async (token, gcode) => {
    return axios({
        url: Queryparams.url + "/running/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}

export const FetchCombis = async (token, gcode) => {
    return axios({
        url: Queryparams.url + "/summary/bets/" + gcode,
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        timeout: 10000,
    }).then(res => ({ err: false, data: res.data }
    )).catch(errmsg => ({ err: true, data: errmsg.response.data }));
}
//========================== HELPER FUNCTIONS ==============================
export function Converter(inString) {
    if (inString !== '') {
        return "₱ " + parseFloat(inString).toLocaleString(navigator.language, { minimumFractionDigits: 2 });
    }
}


export const parseDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function GetLongDate() {
    var dateIn = moment(new Date());
    return dateIn.tz('Asia/Manila').format('MMMM Do YYYY, h:mm:ss a')
}

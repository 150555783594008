import { LockIcon } from '@chakra-ui/icons'
import { Box, IconButton, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { FaMedal } from 'react-icons/fa'
import { clrs } from '../Constants/Constants'

const TopSideBar = ({ setModalLogout, onClickResults, displayType, isSuperAdmin }) => {
    return (
        <Box
            display={'flex'}
            flexDir={'column'}
        >
            <Tooltip
                label='Log Out' placement='right'
            >
                <IconButton
                    onClick={() => setModalLogout(true)}
                    icon={
                        <LockIcon
                            boxSize={30}
                            color={'red.700'}
                        />}
                    marginLeft={2}
                    marginTop={2}
                    h={'50px'}
                    bgColor={'blackAlpha.400'}
                />

            </Tooltip>
            {
                isSuperAdmin &&
                <Tooltip
                    label='Winning Combinations' placement='right'
                >
                    <IconButton
                        onClick={() => onClickResults()}
                        icon={
                            <FaMedal
                                size={30}
                                color={'darkgreen'}
                            />}
                        marginLeft={2}
                        marginTop={2}
                        h={'50px'}
                        bgColor={displayType === "games" ? 'blackAlpha.400' : clrs.bgloginmiddle}
                    />

                </Tooltip>
            }

        </Box>
    )
}

export default TopSideBar
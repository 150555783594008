import React from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

const jsonDP = [
    {
        id: "00:00:01",
        name: "00:00:01"
    },
    {
        id: "05:00:00",
        name: "05:00:00"
    },
    {
        id: "09:00:00",
        name: "09:00:00"
    },
    {
        id: "09:05:00",
        name: "09:05:00"
    },
    {
        id: "09:10:00",
        name: "09:10:00"
    },
    {
        id: "09:15:00",
        name: "09:15:00"
    },
    {
        id: "09:20:00",
        name: "09:20:00"
    },
    {
        id: "09:25:00",
        name: "09:25:00"
    },
    {
        id: "09:30:00",
        name: "09:30:00"
    },
    {
        id: "09:35:00",
        name: "09:35:00"
    },
    {
        id: "09:40:00",
        name: "09:40:00"
    },
    {
        id: "09:45:00",
        name: "09:45:00"
    },
    {
        id: "09:50:00",
        name: "09:50:00"
    },
    {
        id: "09:55:00",
        name: "09:55:00"
    },
    {
        id: "10:00:00",
        name: "10:00:00"
    },
    {
        id: "10:05:00",
        name: "10:05:00"
    },
    {
        id: "10:10:00",
        name: "10:10:00"
    },
    {
        id: "10:15:00",
        name: "10:15:00"
    },
    {
        id: "10:20:00",
        name: "10:20:00"
    },
    {
        id: "10:25:00",
        name: "10:25:00"
    },
    {
        id: "10:30:00",
        name: "10:30:00"
    },
    {
        id: "10:35:00",
        name: "10:35:00"
    },
    {
        id: "10:40:00",
        name: "10:40:00"
    },
    {
        id: "10:45:00",
        name: "10:45:00"
    },
    {
        id: "10:50:00",
        name: "10:50:00"
    },
    {
        id: "10:55:00",
        name: "10:55:00"
    },
    {
        id: "11:00:00",
        name: "11:00:00"
    },
    {
        id: "11:05:00",
        name: "11:05:00"
    },
    {
        id: "11:10:00",
        name: "11:10:00"
    },
    {
        id: "11:15:00",
        name: "11:15:00"
    },
    {
        id: "11:20:00",
        name: "11:20:00"
    },
    {
        id: "11:25:00",
        name: "11:25:00"
    },
    {
        id: "11:30:00",
        name: "11:30:00"
    },
    {
        id: "11:35:00",
        name: "11:35:00"
    },
    {
        id: "11:40:00",
        name: "11:40:00"
    },
    {
        id: "11:45:00",
        name: "11:45:00"
    },
    {
        id: "11:50:00",
        name: "11:50:00"
    },
    {
        id: "11:55:00",
        name: "11:55:00"
    },
    {
        id: "12:00:00",
        name: "12:00:00"
    },
    {
        id: "12:05:00",
        name: "12:05:00"
    },
    {
        id: "12:10:00",
        name: "12:10:00"
    },
    {
        id: "12:15:00",
        name: "12:15:00"
    },
    {
        id: "12:20:00",
        name: "12:20:00"
    },
    {
        id: "12:25:00",
        name: "12:25:00"
    },
    {
        id: "12:30:00",
        name: "12:30:00"
    },
    {
        id: "12:35:00",
        name: "12:35:00"
    },
    {
        id: "12:40:00",
        name: "12:40:00"
    },
    {
        id: "12:45:00",
        name: "12:45:00"
    },
    {
        id: "12:50:00",
        name: "12:50:00"
    },
    {
        id: "12:55:00",
        name: "12:55:00"
    },
    {
        id: "13:00:00",
        name: "13:00:00"
    },
    {
        id: "13:05:00",
        name: "13:05:00"
    },
    {
        id: "13:10:00",
        name: "13:10:00"
    },
    {
        id: "13:15:00",
        name: "13:15:00"
    },
    {
        id: "13:20:00",
        name: "13:20:00"
    },
    {
        id: "13:25:00",
        name: "13:25:00"
    },
    {
        id: "13:30:00",
        name: "13:30:00"
    },
    {
        id: "13:35:00",
        name: "13:35:00"
    },
    {
        id: "13:40:00",
        name: "13:40:00"
    },
    {
        id: "13:45:00",
        name: "13:45:00"
    },
    {
        id: "13:50:00",
        name: "13:50:00"
    },
    {
        id: "13:55:00",
        name: "13:55:00"
    },
    {
        id: "14:00:00",
        name: "14:00:00"
    },
    {
        id: "14:05:00",
        name: "14:05:00"
    },
    {
        id: "14:10:00",
        name: "14:10:00"
    },
    {
        id: "14:15:00",
        name: "14:15:00"
    },
    {
        id: "14:20:00",
        name: "14:20:00"
    },
    {
        id: "14:25:00",
        name: "14:25:00"
    },
    {
        id: "14:30:00",
        name: "14:30:00"
    },
    {
        id: "14:35:00",
        name: "14:35:00"
    },
    {
        id: "14:40:00",
        name: "14:40:00"
    },
    {
        id: "14:45:00",
        name: "14:45:00"
    },
    {
        id: "14:50:00",
        name: "14:50:00"
    },
    {
        id: "14:55:00",
        name: "14:55:00"
    },
    {
        id: "15:00:00",
        name: "15:00:00"
    },
    {
        id: "15:05:00",
        name: "15:05:00"
    },
    {
        id: "15:10:00",
        name: "15:10:00"
    },
    {
        id: "15:15:00",
        name: "15:15:00"
    },
    {
        id: "15:20:00",
        name: "15:20:00"
    },
    {
        id: "15:25:00",
        name: "15:25:00"
    },
    {
        id: "15:30:00",
        name: "15:30:00"
    },
    {
        id: "15:35:00",
        name: "15:35:00"
    },
    {
        id: "15:40:00",
        name: "15:40:00"
    },
    {
        id: "15:45:00",
        name: "15:45:00"
    },
    {
        id: "15:50:00",
        name: "15:50:00"
    },
    {
        id: "15:55:00",
        name: "15:55:00"
    },
    {
        id: "16:00:00",
        name: "16:00:00"
    },
    {
        id: "16:05:00",
        name: "16:05:00"
    },
    {
        id: "16:10:00",
        name: "16:10:00"
    },
    {
        id: "16:15:00",
        name: "16:15:00"
    },
    {
        id: "16:20:00",
        name: "16:20:00"
    },
    {
        id: "16:25:00",
        name: "16:25:00"
    },
    {
        id: "16:30:00",
        name: "16:30:00"
    },
    {
        id: "16:35:00",
        name: "16:35:00"
    },
    {
        id: "16:40:00",
        name: "16:40:00"
    },
    {
        id: "16:45:00",
        name: "16:45:00"
    },
    {
        id: "16:50:00",
        name: "16:50:00"
    },
    {
        id: "16:55:00",
        name: "16:55:00"
    },
    {
        id: "17:00:00",
        name: "17:00:00"
    },
    {
        id: "17:05:00",
        name: "17:05:00"
    },
    {
        id: "17:10:00",
        name: "17:10:00"
    },
    {
        id: "17:15:00",
        name: "17:15:00"
    },
    {
        id: "17:20:00",
        name: "17:20:00"
    },
    {
        id: "17:25:00",
        name: "17:25:00"
    },
    {
        id: "17:30:00",
        name: "17:30:00"
    },
    {
        id: "17:35:00",
        name: "17:35:00"
    },
    {
        id: "17:40:00",
        name: "17:40:00"
    },
    {
        id: "17:45:00",
        name: "17:45:00"
    },
    {
        id: "17:50:00",
        name: "17:50:00"
    },
    {
        id: "17:55:00",
        name: "17:55:00"
    },
    {
        id: "18:00:00",
        name: "18:00:00"
    },
    {
        id: "18:05:00",
        name: "18:05:00"
    },
    {
        id: "18:10:00",
        name: "18:10:00"
    },
    {
        id: "18:15:00",
        name: "18:15:00"
    },
    {
        id: "18:20:00",
        name: "18:20:00"
    },
    {
        id: "18:25:00",
        name: "18:25:00"
    },
    {
        id: "18:30:00",
        name: "18:30:00"
    },
    {
        id: "18:35:00",
        name: "18:35:00"
    },
    {
        id: "18:40:00",
        name: "18:40:00"
    },
    {
        id: "18:45:00",
        name: "18:45:00"
    },
    {
        id: "18:50:00",
        name: "18:50:00"
    },
    {
        id: "18:55:00",
        name: "18:55:00"
    },
    {
        id: "19:00:00",
        name: "19:00:00"
    },
    {
        id: "19:05:00",
        name: "19:05:00"
    },
    {
        id: "19:10:00",
        name: "19:10:00"
    },
    {
        id: "19:15:00",
        name: "19:15:00"
    },
    {
        id: "19:20:00",
        name: "19:20:00"
    },
    {
        id: "19:25:00",
        name: "19:25:00"
    },
    {
        id: "19:30:00",
        name: "19:30:00"
    },
    {
        id: "19:35:00",
        name: "19:35:00"
    },
    {
        id: "19:40:00",
        name: "19:40:00"
    },
    {
        id: "19:45:00",
        name: "19:45:00"
    },
    {
        id: "19:50:00",
        name: "19:50:00"
    },
    {
        id: "19:55:00",
        name: "19:55:00"
    },
    {
        id: "20:00:00",
        name: "20:00:00"
    },
    {
        id: "20:05:00",
        name: "20:05:00"
    },
    {
        id: "20:10:00",
        name: "20:10:00"
    },
    {
        id: "20:15:00",
        name: "20:15:00"
    },
    {
        id: "20:20:00",
        name: "20:20:00"
    },
    {
        id: "20:25:00",
        name: "20:25:00"
    },
    {
        id: "20:30:00",
        name: "20:30:00"
    },
    {
        id: "20:35:00",
        name: "20:35:00"
    },
    {
        id: "20:40:00",
        name: "20:40:00"
    },
    {
        id: "20:45:00",
        name: "20:45:00"
    },
    {
        id: "20:50:00",
        name: "20:50:00"
    },
    {
        id: "20:55:00",
        name: "20:55:00"
    },
    {
        id: "21:00:00",
        name: "21:00:00"
    },
    {
        id: "21:05:00",
        name: "21:05:00"
    },
    {
        id: "21:10:00",
        name: "21:10:00"
    },
    {
        id: "21:15:00",
        name: "21:15:00"
    },
    {
        id: "21:20:00",
        name: "21:20:00"
    },
    {
        id: "21:25:00",
        name: "21:25:00"
    },
    {
        id: "21:30:00",
        name: "21:30:00"
    },
    {
        id: "21:35:00",
        name: "21:35:00"
    },
    {
        id: "21:40:00",
        name: "21:40:00"
    },
    {
        id: "21:45:00",
        name: "21:45:00"
    },
    {
        id: "21:50:00",
        name: "21:50:00"
    },
    {
        id: "21:55:00",
        name: "21:55:00"
    },
    {
        id: "22:00:00",
        name: "22:00:00"
    },
    {
        id: "22:05:00",
        name: "22:05:00"
    },
    {
        id: "22:10:00",
        name: "22:10:00"
    },
    {
        id: "22:15:00",
        name: "22:15:00"
    },
    {
        id: "22:20:00",
        name: "22:20:00"
    },
    {
        id: "22:25:00",
        name: "22:25:00"
    },
    {
        id: "22:30:00",
        name: "22:30:00"
    },
    {
        id: "22:35:00",
        name: "22:35:00"
    },
    {
        id: "22:40:00",
        name: "22:40:00"
    },
    {
        id: "22:45:00",
        name: "22:45:00"
    },
    {
        id: "22:50:00",
        name: "22:50:00"
    },
    {
        id: "22:55:00",
        name: "22:55:00"
    },
    {
        id: "23:00:00",
        name: "23:00:00"
    },
    {
        id: "23:05:00",
        name: "23:05:00"
    },
    {
        id: "23:10:00",
        name: "23:10:00"
    },
    {
        id: "23:15:00",
        name: "23:15:00"
    },
    {
        id: "23:20:00",
        name: "23:20:00"
    },
    {
        id: "23:25:00",
        name: "23:25:00"
    },
    {
        id: "23:30:00",
        name: "23:30:00"
    },
    {
        id: "23:35:00",
        name: "23:35:00"
    },
    {
        id: "23:40:00",
        name: "23:40:00"
    },
    {
        id: "23:45:00",
        name: "23:45:00"
    },
    {
        id: "23:50:00",
        name: "23:50:00"
    },
    {
        id: "23:55:00",
        name: "23:55:00"
    }
]

const MyMenuConfigs = ({ selConf, onClickNewValue }) => {
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {selConf}
            </MenuButton>
            <MenuList height={'300px'} overflowY={'scroll'}>
                {
                    jsonDP.map(jdp => (
                        <MenuItem onClick={() => onClickNewValue(jdp)} key={jdp.id}>{jdp.name}</MenuItem>
                    ))
                }
            </MenuList>
        </Menu>
    )
}

export default MyMenuConfigs
import React, { useState } from 'react'
import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack, useToast } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import MyMenuConfigs from './MyMenuConfigs'
import { UpdateConfigs } from '../Queries/Queries'


const ModalConfigs = ({ isOpen, onClose, data, token, url, onDoneConfigUpdate }) => {
    const [loading, setLoading] = useState(false)
    const [start_bo, setstart_bo] = useState(data.blk_start)
    const [end_bo, setend_bo] = useState(data.blk_end)
    const [start_dp, setstart_dp] = useState(data.drw_start)
    const [end_dp, setend_dp] = useState(data.drw_end)
    const [secnotdup, setsecnotdup] = useState(data.sec_dup)
    const toast = useToast()

    const onSendUpdate = () => {
        setLoading(true)
        var datatosend = {
            gcode: data.gcode,
            drw_start: start_dp,
            drw_end: end_dp,
            blk_start: start_bo,
            blk_end: end_bo,
            sec_dup: parseInt(secnotdup)
        }

        UpdateConfigs(token, url, datatosend).then(res => {
            if (res.data) {
                toast({
                    title: "UPDATE CONFIGURATIONS SUCCESS!",
                    description: res.data.resp,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneConfigUpdate()
            } else {
                toast({
                    title: "UPDATE CONFIGURATIONS ERROR!",
                    description: res.response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneConfigUpdate()
            }
        }).catch(() => {
            toast({
                title: "UPDATE CONFIGURATIONS ERROR!",
                description: "Ooops! There is something wrong updating game configurations.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
            setLoading(false)
            onDoneConfigUpdate()
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={"#4B3F55"} color={'white'}>Update Configurations for {data.gcode}</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Text fontWeight={'bold'} marginBottom={'20px'}>Black Out</Text>
                    <HStack marginLeft={'20px'} marginBottom={'40px'}>
                        {/* start blackout */}
                        <Text>Start</Text>
                        <MyMenuConfigs
                            selConf={start_bo}
                            onClickNewValue={(data) => setstart_bo(data.name)}
                            configs={data}
                        />
                        {/* end blackout */}
                        <Text>End</Text>
                        <MyMenuConfigs
                            selConf={end_bo}
                            onClickNewValue={(data) => setend_bo(data.name)}
                            configs={data}
                        />
                    </HStack>

                    <Text fontWeight={'bold'} marginY={'20px'}>Draw Period</Text>
                    <HStack marginLeft={'20px'} marginBottom={'40px'}>
                        {/* start blackout */}
                        <Text>Start</Text>
                        <MyMenuConfigs
                            selConf={start_dp}
                            onClickNewValue={(data) => setstart_dp(data.name)}
                            configs={data}
                        />
                        {/* end blackout */}
                        <Text>End</Text>
                        <MyMenuConfigs
                            selConf={end_dp}
                            onClickNewValue={(data) => setend_dp(data.name)}
                            configs={data}
                        />
                    </HStack>

                    <Text fontWeight={'bold'} marginY={'20px'}>Entry not Duplicate Interval</Text>
                    <HStack marginLeft={'20px'}>
                        <Text mr={"10px"}>Seconds not Duplicate</Text>
                        <Input
                            textAlign={'center'}
                            w={'100px'}
                            type='number'
                            step={1}
                            min={0}
                            value={secnotdup}
                            onChange={e => setsecnotdup(e.target.value)}
                        />
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {
                        loading ?
                            <Button
                                size={'md'}
                                bg={"#4B3F55"}
                                color={'white'}
                                mr={3}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                <Spinner size={"sm"} />
                                <Text marginLeft={2}>Updating...</Text>
                            </Button> :
                            <Button
                                size={'md'}
                                bg={"#4B3F55"}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendUpdate()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                Update
                            </Button>
                    }
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalConfigs
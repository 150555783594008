import React, { useState } from 'react'
import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack, useToast } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { UpdateControl } from '../Queries/Queries'

const ModalModify = ({ isOpen, onClose, data, token, onDoneUpdate, cdate }) => {
    const toast = useToast()
    const [newcombi, setNewcombi] = useState()
    const [loading, setLoading] = useState(false)


    const onSendCtrl = () => {
        setLoading(true)
        // UpdateControl(url, token, { gcode: data.gcode, combi: newcombi }).then(res => {
        //     if (res.data) {
        //         toast({
        //             title: "MODIFY RESULT SUCCESS!",
        //             description: res.data.resp,
        //             status: 'success',
        //             duration: 3000,
        //             isClosable: true,
        //             position: 'top',
        //             variant: 'left-accent'
        //         })
        //         setLoading(false)
        //         onDoneUpdate()
        //     } else {
        //         toast({
        //             title: "MODIFY RESULT ERROR!",
        //             description: res.response.data.message,
        //             status: 'error',
        //             duration: 3000,
        //             isClosable: true,
        //             position: 'top',
        //             variant: 'left-accent'
        //         })
        //         setLoading(false)
        //         onDoneUpdate()
        //     }
        // }).catch(() => {
        //     toast({
        //         title: "MODIFY RESULT ERROR!",
        //         description: "Ooops! There is something wrong fetching the game controls.",
        //         status: 'error',
        //         duration: 3000,
        //         isClosable: true,
        //         position: 'top',
        //         variant: 'left-accent'
        //     })
        //     setLoading(false)
        //     onDoneUpdate()
        // })
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={"#4B3F55"} color={'white'}>Modify Results for  {data.gcode}</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Text
                        textAlign={'center'}
                        bg={"#4B3F55"}
                        fontSize={"25px"}
                        fontWeight={'bold'}
                        color={'white'}
                        borderRadius={5}
                        marginBottom={"15px"}
                        paddingY={2}
                    >
                        {cdate}
                    </Text>
                    <HStack justifyContent={'center'}>
                        <VStack w={'60%'} alignItems={'flex-start'}>
                            <Text
                                fontWeight={'bold'}
                                paddingY={2}
                            >
                                Current Result
                            </Text>
                            <Text fontWeight={'bold'}>Modify Result</Text>
                        </VStack>
                        <VStack w={'30%'}>
                            <Text
                                bg={"#4B3F55"}
                                color={'white'}
                                paddingY={2}
                                paddingX={7}
                                borderRadius={50}
                                fontWeight={'bold'}
                            >
                                {data.combi ? data.combi : 'None'}
                            </Text>
                            <Input
                                autoFocus
                                color={'black'}
                                size='md'
                                value={newcombi}
                                onChange={e => setNewcombi(e.target.value)}
                                borderColor={"#4B3F55"}
                                textAlign={'center'}
                                type='number'
                                fontWeight={'bold'}
                            />

                        </VStack>
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {
                        loading ?
                            <Button
                                size={'md'}
                                bg={"#4B3F55"}
                                color={'white'}
                                mr={3}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                <Spinner size={"sm"} />
                                <Text marginLeft={2}>Modifying...</Text>
                            </Button> :
                            <Button
                                size={'md'}
                                bg={"#4B3F55"}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendCtrl()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                Modify
                            </Button>
                    }
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalModify
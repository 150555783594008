import React, { useState, useEffect } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { FetchRunning } from '../Queries/Queries';
import TableRunningFetch from './TableRunningFetch';


const RunningFormFetch = ({ selGame, token }) => {
    const [running, setrunning] = useState(null)
    const toast = useToast()
    const onClickDownload = () => {
        GetRunning()
    }

    useEffect(() => {
        GetRunning()
    }, [selGame.gcode])


    const GetRunning = () => {
        setrunning(null)
        selGame.gcode && FetchRunning(token, selGame.gcode).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                console.log(res.data)
                setrunning(res.data)
            }
        }).catch()
    }

    return (
        <Box>
            <TableRunningFetch
                selGame={selGame}
                combis={running}
                onClickDownload={onClickDownload}
            />
        </Box>
    )
}

export default RunningFormFetch
import React from 'react'
import { SimpleGrid, Box, Text, Tooltip, IconButton, Input } from '@chakra-ui/react'
import { FaPencilAlt } from 'react-icons/fa'
import { clrs } from '../Constants/Constants'
import { parseDate } from '../Queries/Queries'

const GameResults = ({ gameResults, rol, currDate, setCurrDate, onSelGameResulttoModify }) => {

    return (
        <Box>
            <Box
                display={'flex'}
                flexDir={'row'}
                marginBottom={"50px"}
            >
                <Text
                    color={'whiteAlpha.700'}
                    fontSize={"20px"}
                    fontWeight={'bold'}
                    marginRight={"15px"}
                >
                    Please select a date :
                </Text>
                <Input
                    color={'whiteAlpha.700'}
                    value={currDate}
                    bg={clrs.bglogin}
                    w={"200px"}
                    size="md"
                    max={parseDate(new Date())}
                    type="date"
                    borderWidth={0}
                    onChange={e => setCurrDate(e.target.value)}
                />
            </Box>
            <SimpleGrid columns={[1, 1, 3, 4, 5]} spacing={10}>
                {
                    gameResults.map((gr, idx) => (
                        <Box key={idx} bg='whiteAlpha.400' borderTopRadius={20} borderBottom={5}>
                            <Text
                                color={'whiteAlpha.700'}
                                textAlign={'center'}
                                fontSize={'20px'}
                                fontWeight={'bold'}
                                paddingY={1}
                            >
                                {gr.gcode.toUpperCase()}
                            </Text>
                            <Box
                                display={'flex'}
                                flexDir={'row'}
                                bg={'blackAlpha.600'}
                                paddingY={5}
                                justifyContent={'space-around'}
                            >
                                <Text
                                    color={'white'}
                                    textAlign={'center'}
                                    fontSize={'30px'}

                                    fontWeight={'bold'}
                                >
                                    {gr.verified ? gr.combi : "-"}
                                </Text>
                                {
                                    gr.verified ?
                                        <Tooltip
                                            label='Modify Result'
                                            placement={'right'}
                                        >
                                            <IconButton
                                                onClick={() => onSelGameResulttoModify(gr)}
                                                _hover={{
                                                    backgroundColor: 'whiteAlpha.400'
                                                }}
                                                boxSize={45}
                                                bg={'transparent'}
                                                icon={
                                                    <FaPencilAlt
                                                        size={25}
                                                        color='orange'
                                                    />
                                                }
                                                color={'white'}
                                            />
                                        </Tooltip>
                                        :
                                        rol === 100 && //SUPER ADMIN ONLY
                                        <Tooltip
                                            label={'Modify Result for ' + gr.gcode.toUpperCase()}
                                            placement={'right'}
                                        >
                                            <IconButton
                                                onClick={() => onSelGameResulttoModify(gr)}
                                                _hover={{
                                                    backgroundColor: 'whiteAlpha.400'
                                                }}
                                                boxSize={45}
                                                bg={'transparent'}
                                                icon={
                                                    <FaPencilAlt
                                                        size={25}
                                                        color='orange'
                                                    />
                                                }
                                                color={'white'}
                                            />
                                        </Tooltip>
                                }
                            </Box>
                        </Box>
                    ))
                }
            </SimpleGrid>
        </Box>

    )
}

export default GameResults
import React, { useState, useEffect } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { Queryparams, clrs } from '../Constants/Constants'
import { useNavigate } from 'react-router-dom';
import { FetchControls, FetchDrawConfigs, FetchGames, FetchResults, parseDate } from '../Queries/Queries';
import Sidebar from './Sidebar';
import ModalSignOut from '../Modals/ModalSignOut';
import TableControl from './TableControl';
import GameResults from './GameResults';
import TopSideBar from './TopSideBar';
import ModalUpdateControl from '../Modals/ModalUpdateControl';
import ModalRemoveControl from '../Modals/ModalRemoveControl';
import ModalAddControl from '../Modals/ModalAddControl';
import ModalModify from '../Modals/ModalModify';
import TableConfigs from './TableConfigs';
import ModalConfigs from '../Modals/ModalConfigs';
import RunningForm from './RunningForm';
import RunningFormFetch from './RunningFormFetch';
var CryptoJS = require("crypto-js");

var Lstore = ""
var user = ""
var userID = 0
var selctrl = {}
var wstoken = ""
var selgresult = {}

const Home = () => {
    const toast = useToast()
    const [games, setGames] = useState([])
    const [drawconfigs, setdrawconfigs] = useState({})
    const [selGame, setSelGame] = useState({})
    const [controls, setControls] = useState([])
    const [displayType, setDisplayType] = useState("games") //games, results
    const [gameResults, setGameResults] = useState([])
    const navigate = useNavigate()

    const [modalLogout, setModalLogout] = useState(false)
    const [modalEditCtrl, setModalEditCtrl] = useState(false)
    const [modalRemoveCtrl, setModalRemoveCtrl] = useState(false)
    const [modalAddCtrl, setModalAddCtrl] = useState(false)
    const [modalModifyResult, setmodalModifyResult] = useState(false)
    const [modalEditConfigs, setmodalEditConfigs] = useState(false)

    const [currDate, setCurrDate] = useState(parseDate(new Date()))

    const onSelGame = (data) => {
        setCurrDate(parseDate(new Date()))
        setDisplayType("games")
        setSelGame(data)
        localStorage.setItem("game", data.gcode)
    }

    useEffect(() => {
        try {
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem('reduxPersistAuth'), Queryparams.powerkey);
            Lstore = bytes.toString(CryptoJS.enc.Utf8);
            user = JSON.parse(Lstore).at;
            userID = JSON.parse(Lstore).rol;
            wstoken = JSON.parse(Lstore).ws;

            FetchGames(user).then(res => {
                setGames(res.data)
                setSelGame(res.data[0])
                localStorage.setItem("game", res.data[0].gcode)
            }).catch((err) => {

            })
        } catch (err) {
            navigate("/")
        }
    }, [navigate])

    const getControls = () => {
        setControls([])
        FetchControls(selGame.gcode, Queryparams.url, user).then(res => {
            if (res.data) {
                setControls(res.data)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH CONTROLS ERROR!",
                    description: res.response.data.message ? res.response.data.message : "Ooops! There is something wrong fetching the game controls.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            toast({
                title: "FETCH CONTROLS ERROR!",
                description: "Ooops! There is something wrong fetching the game controls.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    const getDrawConfigs = () => {
        setdrawconfigs([])
        FetchDrawConfigs(selGame.url, selGame.gcode, user).then(res => {
            if (res.data) {
                setdrawconfigs(res.data)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH DRAW CONFIGS ERROR!",
                    description: res.response.data.message ? res.response.data.message : "Ooops! There is something wrong fetching the game draw configs.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            toast({
                title: "FETCH DRAW CONFIGS ERROR!",
                description: "Ooops! There is something wrong fetching the game draw configs.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }
    useEffect(() => {
        if (selGame.url) {
            getControls()
            getDrawConfigs()
        }
    }, [selGame])

    const getResults = () => {
        setGameResults([])
        FetchResults(currDate, user).then(res => {
            if (res.data) {
                setGameResults(res.data)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH GAMES ERROR!",
                    description: res.response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            toast({
                title: "FETCH GAMES ERROR!",
                description: "Ooops! There is something wrong fetching the game controls.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }
    useEffect(() => {
        getResults()
    }, [currDate])

    const onClickResults = () => {
        setDisplayType("results")
        setSelGame([])
    }

    const LoggingOut = () => {
        localStorage.removeItem("reduxPersistAuth")
        navigate("/login")
    }

    const onSelectControl = (data) => {
        selctrl = data
        setModalEditCtrl(true)
    }

    const onDoneUpdate = () => {
        setModalEditCtrl(false)
        getControls()
    }

    const onSelectRemoveControl = (data) => {
        selctrl = data
        setModalRemoveCtrl(true)
    }

    const onDoneRemoveUpdate = () => {
        setModalRemoveCtrl(false)
        getControls()
    }

    const onSelectAddControl = () => {
        setModalAddCtrl(true)
    }

    const onDoneAddControl = () => {
        setModalAddCtrl(false)
        getControls()
    }

    const onUpdateCurrDate = (data) => {
        setCurrDate(data)
    }

    const onSelGameResulttoModify = (data) => {
        selgresult = data
        setmodalModifyResult(true)
    }

    const onDoneModifyResult = () => {
        setmodalModifyResult(false)
        getResults()
    }

    const onClickEditConfigs = () => {
        setmodalEditConfigs(true)
    }

    const onDoneConfigUpdate = () => {
        setmodalEditConfigs(false)
        getDrawConfigs()
    }
    return (
        <Box h={'100vh'} bg={clrs.bglogin} flexDir={'row'} display={'flex'}>
            {
                modalModifyResult &&
                <ModalModify
                    isOpen={modalModifyResult}
                    onClose={() => setmodalModifyResult(false)}
                    data={selgresult}
                    token={user}
                    onDoneUpdate={() => onDoneModifyResult()}
                    cdate={currDate}
                />
            }
            {
                modalAddCtrl &&
                <ModalAddControl
                    isOpen={modalAddCtrl}
                    onClose={() => setModalAddCtrl(false)}
                    data={selGame}
                    token={user}
                    onDoneUpdate={() => onDoneAddControl()}
                />
            }
            {
                modalRemoveCtrl &&
                <ModalRemoveControl
                    isOpen={modalRemoveCtrl}
                    onClose={() => setModalRemoveCtrl(false)}
                    data={selctrl}
                    token={user}
                    onDoneUpdate={() => onDoneRemoveUpdate()}
                />
            }
            {
                modalEditCtrl &&
                <ModalUpdateControl
                    isOpen={modalEditCtrl}
                    onClose={() => setModalEditCtrl(false)}
                    data={selctrl}
                    url={selGame.url}
                    token={user}
                    onDoneUpdate={() => onDoneUpdate()}
                />
            }
            {
                modalLogout &&
                <ModalSignOut
                    isOpen={modalLogout}
                    onClose={() => setModalLogout(false)}
                    onlogout={() => LoggingOut()}
                />
            }
            {
                modalEditConfigs &&
                <ModalConfigs
                    isOpen={modalEditConfigs}
                    onClose={() => setmodalEditConfigs(false)}
                    data={drawconfigs}
                    token={user}
                    url={selGame.url}
                    onDoneConfigUpdate={onDoneConfigUpdate}
                />
            }
            <Box
                display={'flex'}
                flexDir={'column'}
            >
                <TopSideBar
                    setModalLogout={setModalLogout}
                    onClickResults={onClickResults}
                    displayType={displayType}
                    isSuperAdmin={userID === 100}
                />
                <Sidebar
                    games={games}
                    onSelGame={onSelGame}
                    selGame={selGame}
                />
            </Box>
            {
                displayType === "games" ?
                    <Box w={'98%'} marginTop={2} bg={clrs.bgloginmiddle} marginLeft={1} marginRight={2} borderTopLeftRadius={30} padding={10} display={'flex'} flexDir={'row'} justifyContent={'center'}>
                        <Box width={'300px'}>
                            <TableConfigs
                                configs={drawconfigs}
                                onClickEditConfigs={onClickEditConfigs}
                            />
                        </Box>
                        <Box width={'320px'} mx={"5px"}>
                            <TableControl
                                controls={controls}
                                onSelectControl={onSelectControl}
                                onSelectRemoveControl={onSelectRemoveControl}
                                onSelectAddControl={onSelectAddControl}
                            />
                        </Box>
                        <RunningForm
                            selGame={selGame}
                            token={user}
                            wstoken={wstoken}
                        />
                        <Box mx={"5px"}>
                            <RunningFormFetch
                                selGame={selGame}
                                token={user}
                            />
                        </Box>
                    </Box>
                    :
                    <Box w={'98%'} marginTop={2} bg={clrs.bgloginmiddle} marginLeft={1} marginRight={2} borderTopLeftRadius={30} padding={10} >
                        <GameResults
                            gameResults={gameResults}
                            rol={JSON.parse(Lstore).rol}
                            currDate={currDate}
                            setCurrDate={onUpdateCurrDate}
                            onSelGameResulttoModify={onSelGameResulttoModify}
                        />
                    </Box>
            }
        </Box >
    )
}

export default Home
import React from 'react'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, IconButton, Tooltip, Text } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { Converter } from '../Queries/Queries'
import { FaFileDownload } from 'react-icons/fa'


const TableRunning = ({ combis, onClickDownload }) => {
    return (
        <TableContainer maxHeight={'90vh'} overflowY={'scroll'} width={'250px'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='sm' color={'white'} >
                <Thead >
                    <Tr borderColor={clrs.lable} borderWidth={2} >
                        <Th color={'white'} py={3} flexDir={"row"} display={"flex"}>
                            <Text>Combi</Text>
                            {
                                combis && combis.noc > 0 &&
                                <Text marginLeft={'5px'} fontWeight={'bold'} color={"lime"}>{"[" + combis.noc + "]"}</Text>
                            }
                        </Th>
                        <Th color={'white'} >Amount
                            {
                                combis && combis.details &&
                                <Tooltip
                                    label="Download Combi-Amount"
                                    placement="right"
                                >
                                    <IconButton
                                        onClick={() => onClickDownload()}
                                        marginLeft={"5px"}
                                        boxSize={"30px"}
                                        _hover={{
                                            backgroundColor: 'whiteAlpha.400'
                                        }}
                                        size={'xs'}
                                        bg={'transparent'}
                                        icon={
                                            <FaFileDownload
                                                color='green'
                                                size={"25px"}
                                            />
                                        }
                                        color={'white'}
                                    />
                                </Tooltip>
                            }
                        </Th>
                    </Tr>
                    <Tr borderColor={clrs.lable} borderWidth={2} >
                        <Th color={'lime'} py={3}>Total</Th>
                        <Th color={'lime'} textAlign={'right'} >{Converter((combis && combis.total) ? combis.total : 0)}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        (combis && combis.details) ? combis.details.map((ct, idx) => (
                            <Tr key={idx} borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={ct.so ? 'red' : 'lime'} >{ct.combi}</Td>
                                <Td color={ct.so ? 'red' : 'lime'} style={{ textAlign: 'right' }}>{Converter(ct.bet)}</Td>
                            </Tr>
                        )) :
                            <Tr borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={'whiteAlpha.500'} textAlign={'center'} colSpan={3}>No Running Bets Found.</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default TableRunning